<template>
  <v-dialog
    v-model="isSendAssessment"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>{{ $t("select_channel_and_check") }}</v-card-title>
      <v-card-text>
        <div align="center">
          <h2>
            {{ $t("remaining_sms_credit") }} {{ sms_data.shop_sms_all }}
          </h2>
        </div>
        <v-checkbox
          v-model="send_line"
          true-value="1"
          false-value="0"
          :label="`${$t('send_notification')} LINE`"
        ></v-checkbox>
        <v-checkbox
          v-model="send_sms"
          true-value="1"
          false-value="0"
          :label="`${$t('send_notification')} SMS`"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="isLoading"
          :disabled="isLoading || sms_data.shop_sms_all<=0"
          @click="sendAssessment"
        >
          {{ $t("send_assessment") }}
        </v-btn>
        <v-btn
          outlined
          color="secondary"
          @click="$emit('update:isSendAssessment',false)"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import sms from '@/api/sms'
import assessment from '@/api/WebsiteManageMent/assessment'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default {
  model: {
    prop: 'isSendAssessment',
    event: 'update:isSendAssessment',
  },
  props: {
    isSendAssessment: {
      type: Boolean,
      default: false,
    },
    assessmentId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const send_line = ref('1')
    const send_sms = ref('1')
    const sms_data = ref('')
    const isLoading = ref(false)

    const getSms = () => {
      sms.smsGet().then(res => {
        sms_data.value = res
      })
    }

    const sendAssessment = () => {
      isLoading.value = true
      assessment.assessmentSend({
        ass_id: props.assessmentId,
        send_line: send_line.value,
        send_sms: send_sms.value,
        lang: i18n.locale,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onSend')
        emit('update:isSendAssessment', false)
        isLoading.value = false
      })
    }

    watch(() => props.isSendAssessment, value => {
      if (value) {
        getSms()
      }
    })

    return {
      send_line,
      send_sms,
      isLoading,
      sms_data,
      sendAssessment,

    }
  },
}
</script>
