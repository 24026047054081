import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const promotionList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'product/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get promotions list err : ', err)

      return {}
    })

  return response
}

const promotionGet = async param => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'product',
      param,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get promotions  err : ', err)

      return {}
    })

  return response
}

const promotionCourseList = async param => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'product/course',
      param,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get course list err : ', err)

      return {}
    })

  return response
}

const promotionServiceTypeList = async () => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'product/category',
    })
    .then(res => res.data || [])
    .catch(err => {
      console.log('get course list err : ', err)

      return []
    })

  return response
}

const promotionCourseAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'product/course',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get course list err : ', err)

      return {}
    })

  return response
}

const promotionDrugList = async param => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'product/drug',
      param,
    })
    .then(res => res.data || [])
    .catch(err => {
      console.log('get drug list err : ', err)

      return []
    })

  return response
}

const promotionDrugOrderList = async param => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'product/drugorder',
      param,
    })
    .then(res => res.data || [])
    .catch(err => {
      console.log('get drug order list err : ', err)

      return []
    })

  return response
}

const promotionDrugAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'product/drug',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add drug promotion err : ', err)

      return {}
    })

  return response
}

const promotionCourseUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'product/courseedit',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update course promotion err : ', err)

      return {}
    })

  return response
}

const promotionDrugUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'product/drugedit',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update drug promotion err : ', err)

      return {}
    })

  return response
}

const promotionGalleryAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'product/gallery',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update drug promotion err : ', err)

      return {}
    })

  return response
}
const promotionGalleryRemove = async (productId, id) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'product/gallery',
      param: `${productId}/${id}`,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('delete drug promotion err : ', err)

      return {}
    })

  return response
}

const promotionActive = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'product/active',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('send check promotion err : ', err)

      return {}
    })

  return response
}

const promotionStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'product/status',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update status promotion err : ', err)

      return {}
    })

  return response
}
export default {
  promotionList,
  promotionCourseList,
  promotionServiceTypeList,
  promotionCourseAdd,
  promotionDrugList,
  promotionDrugOrderList,
  promotionDrugAdd,
  promotionGet,
  promotionCourseUpdate,
  promotionDrugUpdate,
  promotionGalleryAdd,
  promotionGalleryRemove,
  promotionActive,
  promotionStatusUpdate,
}
