<template>
  <v-dialog
    v-model="isAddNewAssessment"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('add_assessment') }}
      </v-card-title>
      <v-form
        ref="formAddAssessment"
        @submit.prevent="addAssessment"
      >
        <v-card-text>
          <v-autocomplete
            v-model="customerSelect"
            :items="customerList"
            :label="$t('choose_a_checker')"
            :placeholder="$t('input_three_charector')"
            item-text="customer_fullname"
            outlined
            :filter="customFilter"
            dense
            :rules="[required]"
            :search-input.sync="searchTextCustomer"
            :no-data-text="statusText"
            :loading="searchCustomerLoading"
            auto-select-first
            item-value="customer_id_pri"
          >
            <template v-slot:item="{ item }">
              {{ item.customer_fullname }}
              ({{ item.customer_id }})
            </template>
            <template v-slot:selection="{ item }">
              {{ item.customer_fullname }}
              ({{ item.customer_id }})
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model.trim="staffSelect"
            :items="staffData"
            item-text="user_fullname"
            item-value="user_id"
            chips
            :rules="[required]"
            outlined
            :label="$t('staff')"
            dense
            small-chips
            multiple
            auto-select-first
          ></v-autocomplete>
          <v-autocomplete
            v-model.trim="doctorSelect"
            :items="doctorData"
            item-text="user_fullname"
            :rules="[required]"
            chips
            outlined
            small-chips
            dense
            :label="$t('doctor')"
            multiple
            auto-select-first
            item-value="user_id"
          ></v-autocomplete>
          <v-autocomplete
            v-model="courseSelect"
            :items="courseData"
            chips
            :rules="[required]"
            auto-select-first
            small-chips
            multiple
            outlined
            :label="$t('course')"
            dense
            item-text="course_name"
            item-value="course_id_pri"
          >
            <template v-slot:item="{ item }">
              {{ item.course_name }}
              ({{ item.course_id }})
            </template>
            <template v-slot:selection="{ item }">
              {{ item.course_name }}
              ({{ item.course_id }}),
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model="tags"
            :items="tagList"
            chips
            :rules="[required]"
            auto-select-first
            small-chips
            multiple
            outlined
            :label="$t('customer_tag')"
            dense
            item-text="customer_tag_name"
            item-value="customer_tag_name"
          >
          </v-autocomplete>
          <!-- <v-text-field
            v-model="tag"
            outlined
            dense
            hide-details
            :label="`${$t('add_customer_tags')} (${$t('pressEnterForAddTag')})`"
            @keypress.enter="addTag"
          ></v-text-field> -->
          <v-chip
            v-for="(item,index) in tags"
            :key="index"
            close
            color="primary"
            small
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text ma-2 my-2`"
            @click:close="removeTag(index)"
          >
            {{ item }}
          </v-chip>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="addAssessment"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:isAddNewAssessment', false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import customerSelection from '@/api/customerSelection'
import { i18n } from '@/plugins/i18n'
import { required } from '@/@core/utils/validation'
import assessment from '@/api/WebsiteManageMent/assessment'
import userRole from '@/api/userRole'
import store from '@/store'
import promotion from '@/api/WebsiteManageMent/promotion'
import customerTag from '@/api/customer/customerTag'

export default {
  model: {
    prop: 'isAddNewAssessment',
    event: 'update:isAddNewAssessment',
  },
  props: {
    isAddNewAssessment: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const customerList = ref([])
    const staffData = ref([])
    const doctorData = ref([])
    const courseData = ref([])
    const customerSelect = ref('')
    const staffSelect = ref([])
    const doctorSelect = ref([])
    const courseSelect = ref([])
    const searchTextCustomer = ref('')
    const searchCustomerLoading = ref(false)
    const isLoading = ref(false)
    const statusText = ref('')
    const formAddAssessment = ref(null)
    const tags = ref([])
    const tag = ref('')
    const tagList = ref([])

    onMounted(() => {
      getStaffList()
      getDoctorList()
      getCourseList()
      getCustomerTag()
    })

    const getCustomerTag = () => {
      customerTag.customerTagList({
        searchtext: '',
        active_page: 1,
        per_page: 500,
      }).then(res => {
        tagList.value = res.data
      })
    }
    const getCustomerList = () => {
      customerSelection.customerSelectionList({
        searchtext: searchTextCustomer.value,
      }).then(res => {
        customerList.value = res
        searchCustomerLoading.value = false
        statusText.value = !res.length ? i18n.t('no_data') : i18n.t('input_three_charector')
      })
    }

    const getStaffList = () => {
      assessment.assessmentStaffList().then(res => {
        staffData.value = res
      })
    }

    const getDoctorList = () => {
      userRole.userRoleList({
        role_id: [1, 3],
      }).then(res => {
        doctorData.value = res
      })
    }

    const getCourseList = () => {
      promotion.promotionCourseList().then(res => {
        courseData.value = res
      })
    }

    const addAssessment = () => {
      const isFormValid = formAddAssessment.value.validate()
      if (!isFormValid) return
      isLoading.value = true
      assessment.assessmentAdd({
        customer_id_pri: customerSelect.value,
        consult: staffSelect.value,
        doctor: doctorSelect.value,
        course: courseSelect.value,
        tag: JSON.stringify(tags.value).replaceAll('"', '').replaceAll('[', '').replaceAll(']', ''),
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onAdd')
        emit('update:isAddNewAssessment', false)
        isLoading.value = false
      })
    }

    const customFilter = (item, queryText) => {
      const textOne = item.customer_fullname.toLowerCase()
      const textThree = item.customer_id.toLowerCase()
      const textFour = item.customer_tel.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        textOne.indexOf(searchText) > -1
      || textThree.indexOf(searchText) > -1
      || textFour.indexOf(searchText) > -1
      )
    }
    watch(searchTextCustomer, value => {
      if (value && value.length >= 3) {
        statusText.value = i18n.t('data_loading')
        searchCustomerLoading.value = true
        getCustomerList()
      } else {
        customerList.value = []
        statusText.value = i18n.t('input_three_charector')
      }
    })
    watch(() => props.isAddNewAssessment, value => {
      if (value) {
        customerSelect.value = ''
        staffSelect.value = []
        doctorSelect.value = []
        courseSelect.value = []
      }
    })

    const addTag = () => {
      tags.value.push(tag.value)
      tag.value = ''
    }

    const removeTag = index => {
      tags.value.splice(index, 1)
    }

    return {
      customerList,
      addTag,
      staffData,
      doctorData,
      courseData,
      removeTag,
      customerSelect,
      tags,
      staffSelect,
      doctorSelect,
      courseSelect,
      isLoading,
      searchCustomerLoading,
      statusText,
      searchTextCustomer,
      tag,
      customFilter,
      required,
      addAssessment,
      formAddAssessment,
      tagList,
    }
  },
}
</script>
