import basicStatus from '@/@fake-db/data/basicStatus.json'
import assessment from '@/api/WebsiteManageMent/assessment'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useDocManagement() {
  const searchtext = ref('')
  const statusSelected = ref('')
  const statusList = ref(basicStatus.data)
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const options = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'ass_id',
    },
    { text: i18n.t('code'), value: 'ass_code' },
    {
      text: i18n.t('firstname_lastname'),
      value: 'customer_fullname',
    },
    { text: i18n.t('staff'), value: 'user_fullname' },
    { text: i18n.t('status'), value: 'ass_status_id', align: 'center' },
    {
      text: i18n.t('receive_points'),
      value: 'ass_point',
      align: 'end',
    },
    {
      text: i18n.t('status_assessment'),
      value: 'assessment_status_name',
      align: 'center',
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 230,
      align: 'center fixed',
    },
  ])

  const isAddNewAssessment = ref(false)

  const isEditAssessment = ref(false)
  const assessmentId = ref('')

  const isSendAssessment = ref(false)

  const isUpdateStatus = ref(false)
  const loadingStatusUpdate = ref(false)
  const statusId = ref(false)

  const isRemove = ref(false)

  const updateStatus = () => {
    loadingStatusUpdate.value = true
    assessment
      .assessmentStatusUpdate({
        ass_id: assessmentId.value,
        ass_status_id: statusId.value == '1' ? '2' : '1',
      })
      .then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        isUpdateStatus.value = false
        loadingStatusUpdate.value = false
        fetchDataTable()
      })
  }

  const removeAssessment = () => {
    loadingStatusUpdate.value = true
    assessment.assessmentRemove(assessmentId.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      isRemove.value = false
      loadingStatusUpdate.value = false
      fetchDataTable()
    })
  }

  const fetchDataTable = () => {
    loading.value = true
    assessment
      .assessmentList({
        searchtext: searchtext.value,
        ass_status_id: statusSelected.value,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      })
      .then(res => {
        const { data, count, segment, count_of_page } = res
        dataTableList.value = data
        totalDataTableList.value = count
        segmentId.value = segment
        totalPage.value = count_of_page
        loading.value = false
      })
  }
  const copy = () => {
    store.commit('app/ALERT', {
      message: 'coppySuccess',
      color: 'success',
    })
  }
  watch([searchtext, statusSelected, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    searchtext,
    statusSelected,
    statusList,
    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    totalPage,
    loading,
    footer,
    isRemove,
    columns,
    isAddNewAssessment,
    fetchDataTable,
    removeAssessment,
    updateStatus,
    copy,
    isUpdateStatus,
    loadingStatusUpdate,
    statusId,
    isEditAssessment,
    assessmentId,
    isSendAssessment,
  }
}
