var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.isAddNewAssessment),callback:function ($$v) {_vm.isAddNewAssessment=$$v},expression:"isAddNewAssessment"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('add_assessment'))+" ")]),_c('v-form',{ref:"formAddAssessment",on:{"submit":function($event){$event.preventDefault();return _vm.addAssessment.apply(null, arguments)}}},[_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.customerList,"label":_vm.$t('choose_a_checker'),"placeholder":_vm.$t('input_three_charector'),"item-text":"customer_fullname","outlined":"","filter":_vm.customFilter,"dense":"","rules":[_vm.required],"search-input":_vm.searchTextCustomer,"no-data-text":_vm.statusText,"loading":_vm.searchCustomerLoading,"auto-select-first":"","item-value":"customer_id_pri"},on:{"update:searchInput":function($event){_vm.searchTextCustomer=$event},"update:search-input":function($event){_vm.searchTextCustomer=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_fullname)+" ("+_vm._s(item.customer_id)+") ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_fullname)+" ("+_vm._s(item.customer_id)+") ")]}}]),model:{value:(_vm.customerSelect),callback:function ($$v) {_vm.customerSelect=$$v},expression:"customerSelect"}}),_c('v-autocomplete',{attrs:{"items":_vm.staffData,"item-text":"user_fullname","item-value":"user_id","chips":"","rules":[_vm.required],"outlined":"","label":_vm.$t('staff'),"dense":"","small-chips":"","multiple":"","auto-select-first":""},model:{value:(_vm.staffSelect),callback:function ($$v) {_vm.staffSelect=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"staffSelect"}}),_c('v-autocomplete',{attrs:{"items":_vm.doctorData,"item-text":"user_fullname","rules":[_vm.required],"chips":"","outlined":"","small-chips":"","dense":"","label":_vm.$t('doctor'),"multiple":"","auto-select-first":"","item-value":"user_id"},model:{value:(_vm.doctorSelect),callback:function ($$v) {_vm.doctorSelect=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"doctorSelect"}}),_c('v-autocomplete',{attrs:{"items":_vm.courseData,"chips":"","rules":[_vm.required],"auto-select-first":"","small-chips":"","multiple":"","outlined":"","label":_vm.$t('course'),"dense":"","item-text":"course_name","item-value":"course_id_pri"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.course_name)+" ("+_vm._s(item.course_id)+") ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.course_name)+" ("+_vm._s(item.course_id)+"), ")]}}]),model:{value:(_vm.courseSelect),callback:function ($$v) {_vm.courseSelect=$$v},expression:"courseSelect"}}),_c('v-autocomplete',{attrs:{"items":_vm.tagList,"chips":"","rules":[_vm.required],"auto-select-first":"","small-chips":"","multiple":"","outlined":"","label":_vm.$t('customer_tag'),"dense":"","item-text":"customer_tag_name","item-value":"customer_tag_name"},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}}),_vm._l((_vm.tags),function(item,index){return _c('v-chip',{key:index,class:("v-chip-" + (_vm.$vuetify.theme.dark?'dark': 'light') + "-bg primary--text ma-2 my-2"),attrs:{"close":"","color":"primary","small":""},on:{"click:close":function($event){return _vm.removeTag(index)}}},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":_vm.addAssessment}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit('update:isAddNewAssessment', false)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }