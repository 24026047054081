import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const assessmentList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'assessment/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get assessment list err : ', err)

      return {}
    })

  return response
}
const assessmentStaffList = async () => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'assessment/user',
    })
    .then(res => res.data || [])
    .catch(err => {
      console.error('get user list err : ', err)

      return []
    })

  return response
}

const assessmentCourseList = async () => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'assessment/course',
    })
    .then(res => res.data || [])
    .catch(err => {
      console.error('get course list err : ', err)

      return []
    })

  return response
}

const assessmentAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'assessment',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add assessment err : ', err)

      return {}
    })

  return response
}

const assessmentGet = async param => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'assessment',
      param,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.error('get assessment err : ', err)

      return {}
    })

  return response
}
const assessmentUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'assessment',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update assessment err : ', err)

      return {}
    })

  return response
}

const assessmentSend = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'assessment/send',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('send assessment err : ', err)

      return {}
    })

  return response
}
const assessmentStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'assessment/status',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update status assessment err : ', err)

      return {}
    })

  return response
}

const assessmentRemove = async param => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'assessment',
      param,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('delete assessment err : ', err)

      return {}
    })

  return response
}
const assessmentReport = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'reportforms',
      body,
    })
    .then(res => res.data || [])
    .catch(err => {
      console.error('report assessment2  err : ', err)

      return {}
    })

  return response
}

export default {
  assessmentList,
  assessmentStaffList,
  assessmentCourseList,
  assessmentAdd,
  assessmentGet,
  assessmentUpdate,
  assessmentSend,
  assessmentStatusUpdate,
  assessmentRemove,
  assessmentReport,
}
