<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t("Assessment") }}  <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewAssessment = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>   {{ $t("add_assessment") }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          icon
          @click="isAddNewAssessment = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2 mb-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            dense
            outlined
            hide-details
            :label="$t('search')"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-select
            v-model.trim="statusSelected"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        hide-default-footer
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.ass_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.ass_status_id`]="{ item }">
          <StatusBlock :status="item.ass_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="assessmentId = item.ass_id;isEditAssessment = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="assessmentId = item.ass_id;isSendAssessment = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiSend }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("send_assessment") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="assessmentId = item.ass_id;statusId=item.ass_status_id;isUpdateStatus = true"
                v-on="on"
              >
                <v-icon>{{ item.ass_status_id=='1'? icons.mdiClose:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.ass_status_id=='1'? $t("suspend"): $t("normal") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="assessmentId = item.ass_id;isRemove = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("delete") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-clipboard:copy="item.ass_link"
                v-clipboard:success="copy"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiContentCopy }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("copy_link") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <AddNewDoc
      v-model="isAddNewAssessment"
      @onAdd="fetchDataTable"
    />
    <EditDocs
      v-model="isEditAssessment"
      :assessment-id="assessmentId"
      @onUpdate="fetchDataTable"
    />
    <SendAssessment
      v-model="isSendAssessment"
      :assessment-id="assessmentId"
      @onSend="fetchDataTable"
    />
    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ statusId=='1' ?$t('confirmSuspendAssessment'):$t('cancelSuspendAssessment') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="loadingStatusUpdate "
            :disabled="loadingStatusUpdate "
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="sedondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isRemove"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ $t('comfirmDeleteAssessment') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="loadingStatusUpdate "
            :disabled="loadingStatusUpdate "
            @click="removeAssessment"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="sedondary"
            outlined
            @click="isRemove = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiSend, mdiClose, mdiCheck, mdiDeleteOutline, mdiContentCopy, mdiAlertOutline,
} from '@mdi/js'
import useDocManagement from './useDocManagement'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewDoc from './AddNewDocs.vue'
import EditDocs from './EditDocs.vue'
import SendAssessment from './SendAssessment.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    StatusBlock,
    AddNewDoc,
    EditDocs,
    Pagination,
    SendAssessment,
  },
  setup() {
    return {
      ...useDocManagement(),
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiSend,
        mdiClose,
        mdiCheck,
        mdiDeleteOutline,
        mdiContentCopy,
        mdiAlertOutline,
      },
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
